<template>
  <div class="page-thank-you">
    <div class="container">
      <h1 class="thank-you__title">Hartelijk dank voor je aanvraag!</h1>
      <p class="thank-you__body">
        De professionals nemen zo spoedig mogelijk contact met je op.
      </p>
    </div>

    <div class="container">
      <div id="usps" class="usps__container">
        <h2 class="usps__title">Tips om even door te nemen</h2>
        <Usps :usps="usps" />
      </div>
    </div>
  </div>
</template>

<script>
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import AbstractPage from 'chimera/all/components/page/AbstractPage'

export default {
  components: {
    Usps,
  },

  extends: AbstractPage,

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Aanvraag afgerond',
      headDescription:
        'Vergelijk verhuisbedrijven op Alleverhuizers.nl. Snel, betrouwbaar en alleen offertes uit jouw regio. Bespaar tot wel 40% op verhuiskosten.',
      path: '/offertes-aanvragen/aanvraag-afgerond',
      usps: [
        {
          text: 'Zorg dat je jouw telefoon en agenda bij de hand hebt, want professionals kunnen je telefonisch benaderen.',
          logo: require('chimera/all/themes/blueflow/images/usps/calendar.svg'),
        },
        {
          text: 'Kijk niet alleen naar de prijs, maar vergelijk ook op kwaliteit, service en betrouwbaarheid.',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          text: 'Neem rustig de tijd om voor de juiste professional te kiezen.',
          logo: require('chimera/all/themes/blueflow/images/usps/safety.svg'),
        },
      ],
    }
  },
}
</script>
